@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200");

@font-face {
  font-family: BananaGrotesk;
  src: url('fonts/BananaGrotesk-Regular.otf') format('opentype');
}

html,
body {
  height: 100%;
}

html {
  background-color: #000000;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

* {
  box-sizing: border-box;
}

#root {
  width: 100%;
  min-height: 100%;
}

@font-face {
  font-family: "JakartaSans";
  src: url("./assets/webfonts/PlusJakartaSans-Regular.woff2") format("woff2"),
    url("./assets/webfonts/PlusJakartaSans-Regular.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "JakartaSans";
  src: url("./assets/webfonts/PlusJakartaSans-Bold.woff2") format("woff2"),
    url("./assets/webfonts/PlusJakartaSans-Bold.woff") format("woff");
  font-weight: bold;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}
